// 
// page-title.scss
//

.page-title-box {
    height: 6vh;
    background-color: $card-bg;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: 0 -27px 30px;
    padding: 15px 27px;
    box-shadow: 0 1px 1px rgba(50, 58, 70, .1);

    .page-title {
        font-size: 1.1rem;
        margin: 0;
        color: var(--#{$prefix}gray-900);
    }

    .breadcrumb {
        padding: 0;
    }
}

.page-title-box-alt {
    background-color: transparent;
    padding: 24px 27px;
    box-shadow: none;
    margin-bottom: 0;
}

@media (max-width: 639px) {
    .page-title-box {
        display: block;

        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .page-title-right {
            display: block;
            padding-top: 5px;
        }
    }
}
