//
// _horizontal.scss
//

// Container width
html[data-layout-mode="horizontal"] {
  @include media-breakpoint-up(xl) {
    .container-fluid {
      max-width: $horizontal-layout-width;
    }
  }

  .navbar-custom {
    padding: 0 $grid-gutter-width;
    left: 0;
    right: 0;

    .logo-box {
      display: block;
      background-color: transparent;
    }
  }

  .content-page {
    margin-left: 0 !important;
  }
}

.topnav {
  background: $menu-bg;
  box-shadow: var(--#{$prefix}box-shadow);
  border-top: 1px solid $topbar-search-bg;
  margin-top: $topbar-height;
  padding: 0 calc(#{$grid-gutter-width} * 0.5);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;

  .topnav-menu {
    margin: 0;
    padding: 0;
  }

  .navbar-nav {
    .nav-link {
      font-size: $menu-item-size;
      position: relative;
      line-height: 22px;
      padding: calc(#{$topnav-height - 22px} * 0.5) 1.1rem;
      color: $menu-item-color;
      font-family: $font-family-secondary;

      i {
        font-size: 15px;
        display: inline-block;
        vertical-align: middle;
      }

      &:focus,
      &:hover {
        color: $menu-item-hover;
        background-color: transparent;
      }
    }

    .dropdown-item {
      &.active,
      &:hover {
        color: $primary;
      }
    }

    .nav-item {
      .dropdown.active {
        > a.dropdown-toggle {
          color: $primary;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .topnav {
    height: $topnav-height;

    .navbar-nav {
      .nav-item {
        &:first-of-type {
          .nav-link {
            padding-left: 0;
          }
        }
      }
    }

    .dropdown-item {
      padding: 0.5rem 1.25rem;
      min-width: 180px;
      margin: 0 0.3rem;
      width: auto;

      &.active {
        background-color: transparent;
        color: $primary;
      }
    }

    .dropdown {
      &.mega-dropdown {
        // position: static;
        .mega-dropdown-menu {
          left: 0px;
          right: auto;
        }
      }

      .dropdown-menu {
        padding: 0.3rem 0;
        margin-top: 0;
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

        .arrow-down {
          &::after {
            right: 20px;
            transform: rotate(-135deg) translateY(-50%);
            position: absolute;
          }
        }

        .dropdown {
          .dropdown-menu {
            position: absolute;
            top: 0 !important;
            left: 100%;
            display: none;
          }
        }
      }

      &:hover {
        > .nav-link {
          color: $menu-item-hover;
        }

        > .dropdown-menu {
          display: block;

          > .dropdown:hover {
            > .dropdown-item {
              color: $primary;
            }

            > .dropdown-menu {
              display: block;
            }
          }
        }
      }

      &.active {
        > .nav-link {
          color: $menu-item-active;
        }
      }
    }
  }

  .navbar-toggle {
    display: none;
  }

  html[data-layout-mode="horizontal"] {
    .content-page {
      padding: calc(#{$topbar-height} + #{$topnav-height}) 12px 65px 12px;
    }
  }
}

.arrow-down {
  display: inline-block;

  &:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: 0.4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all 0.3s ease-out;
    width: 0.4em;
  }
}

@include media-breakpoint-down(xl) {
  .topnav-menu {
    .navbar-nav {
      li {
        &:last-of-type {
          .dropdown {
            .dropdown-menu {
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;

    .navbar-nav {
      .nav-link {
        padding: 0.75rem 1.1rem;
      }
    }

    .dropdown {
      .dropdown-menu {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 15px;
      }

      .dropdown-item {
        position: relative;
        background-color: transparent;

        &.active,
        &:active {
          color: $menu-item-active;
        }
      }
    }

    .arrow-down {
      &::after {
        right: 15px;
        position: absolute;
      }
    }
  }
}

html[data-layout-mode="horizontal"][data-layout-width="boxed"] {
  .topnav {
    max-width: $boxed-layout-width;
    margin: $topbar-height auto 0;
  }
}
