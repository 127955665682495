// 
// report.scss
//


.report.map {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - $topbar-height - $footer-height - $bread-crumb-height);

    .row > * {
        margin-bottom: 20px;
    }

    .row > *:nth-last-child(-n + 1) {
        margin-bottom: 0px;
    }

    #filters .row > * {
        margin-bottom: 0px !important;
    }

    .filter-wraper:nth-child(even) {
        padding-left: 10px;
    } 

    .leaflet-container path:focus{
        outline: none;
    } 

    .leaflet-tooltip {
        display: flex;
        flex-direction: column;
    }
    .hex-poligon {
        cursor: grab;
    }

    .leaflet-tooltip {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        transition-delay: 500ms;
    }

    .leaflet-tooltip {
        opacity: 0.9;
    }
}