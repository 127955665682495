//
// pricing.scss
//

.card-pricing {
  position: relative;

  .card-pricing-plan-name {
    padding-bottom: 20px;
  }

  .card-pricing-icon {
    font-size: 22px;
    background-color: rgba($primary, 0.1);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 62px;
    border-radius: 50%;
  }

  .card-pricing-price {
    padding: 30px 0 0;

    span {
      font-size: 40%;
      color: $text-muted;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .card-pricing-features {
    color: $text-muted;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;

    li {
      padding: 10px;
    }
  }
}

.card-pricing-recommended {
  background-color: $primary;
  color: $white;

  .card-pricing-icon {
    background-color: rgba($white, 0.1);
  }
  .card-pricing-features,
  .card-pricing-price span {
    color: rgba($white, 0.7);
  }
}
