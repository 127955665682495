//
// Daterange
//

.daterangepicker {
  font-family: $font-family-base;
  border: $dropdown-border-width solid $dropdown-border-color;
  box-shadow: var(--#{$prefix}box-shadow);
  background-color: $dropdown-bg;

  .calendar-table {
    border: 1px solid $dropdown-bg;
    background-color: $dropdown-bg;

    td,
    th {
      color: var(--#{$prefix}gray-600);
    }

    .next,
    .prev {
      span {
        border-color: var(--#{$prefix}gray-500);
      }
    }

    select {
      color: $input-color !important;
      background-color: $input-bg !important;
      border: $input-border-width solid $input-border-color !important;
    }
  }

  .ranges {
    li {
      &:hover {
        background-color: $dropdown-link-hover-bg;
      }
      &.active {
        background-color: $primary;
      }
    }
  }

  td {
    &.in-range {
      background-color: var(--#{$prefix}gray-100);
      color: var(--#{$prefix}gray-700);
    }
    &.off,
    &.off.end-date,
    &.off.in-range,
    &.off.start-date {
      background-color: $dropdown-bg;
      color: $dropdown-link-color;
      opacity: 0.5;
    }

    &.active,
    &.active:hover {
      background-color: $primary;
      color: $white;
    }
  }

  td.available:hover,
  th.available:hover {
    background-color: $primary;
    color: $white;
  }

  &:after {
    border-bottom: 6px solid $dropdown-bg;
  }
  &:before {
    border-bottom: 7px solid $dropdown-border-color;
  }

  .drp-buttons {
    border-top: 1px solid $dropdown-border-color;
  }

  select {
    &.ampmselect,
    &.hourselect,
    &.minuteselect,
    &.secondselect {
      background: $dropdown-bg;
      border: 1px solid $dropdown-bg;
      color: $dropdown-link-color;
    }
  }

  &.show-ranges {
    .drp-calendar.left {
      border-left: 1px solid $dropdown-border-color !important;
    }
  }
}
