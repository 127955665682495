//
// tippy.scss
//

.tippy-tooltip {
  .light-theme[data-animatefill] {
    background-color: transparent;
  }
}

.light-theme {
  color: color-yiq($white);
  box-shadow: var(--#{$prefix}box-shadow-lg);
  background-color: $white;

  .tippy-backdrop {
    background-color: $white;
  }

  .tippy-roundarrow {
    fill: $white;
  }
}

.gradient-theme {
  .tippy-backdrop {
    background: $primary;
    background: linear-gradient(to left, $danger, $primary);
  }
}

.tippy-popper {
  &[x-placement^="top"] {
    .tippy-tooltip {
      &.light-theme {
        .tippy-arrow {
          border-top: 7px solid $white;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
        }
      }
    }
  }
  &[x-placement^="bottom"] {
    .tippy-tooltip {
      &.light-theme {
        .tippy-arrow {
          border-bottom: 7px solid $white;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
        }
      }
    }
  }
  &[x-placement^="left"] {
    .tippy-tooltip {
      &.light-theme {
        .tippy-arrow {
          border-left: 7px solid $white;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
        }
      }
    }
  }
  &[x-placement^="right"] {
    .tippy-tooltip {
      &.light-theme {
        .tippy-arrow {
          border-right: 7px solid $white;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
        }
      }
    }
  }
}
