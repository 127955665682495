//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Modal full width
.modal-full-width {
  width: 95%;
  max-width: none;
}

// Modal Positions
.modal-top {
  margin: 0 auto;
}

// Right Modal
.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: $modal-content-bg;
  align-content: center;
  transform: translate(25%, 0) !important;

  button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.modal {
  &.show {
    .modal-right,
    .modal-left {
      transform: translate(0, 0) !important;
    }
  }
}

// Bottom modal
.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

// Colored modal header
.modal-colored-header {
  color: $white;
  border-radius: 0;

  .close {
    color: $white !important;
  }
}

// Custom animation modal
.modal-demo {
  background-color: $white;
  width: 600px !important;
  border-radius: 4px;
  display: none;
  position: relative;
  overflow: hidden;

  .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $light;
  }
}

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important;
  }
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $primary;
  color: $white;
  text-align: left;
  margin: 0;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-wrapper {
  text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
  .close {
    top: 20px;
    z-index: 9999;
  }
}

// Model
// .modal-open {
//     padding-right: 0px !important;
//     padding-left: 0px !important;
// }
