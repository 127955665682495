//
// two-column-menu.scss
//

html[data-layout-mode="two-column"] {
  .left-side-menu {
    width: calc(#{$twocolumn-sidebar-iconview-width} + #{$twocolumn-sidebar-width});
    background-color: transparent;
    box-shadow: none;
  }

  .sidebar-icon-menu {
    position: fixed;
    width: $twocolumn-sidebar-iconview-width;
    z-index: 500;
    top: 0;
    bottom: 0;
    padding-bottom: 20px;
    background-color: $twocolumn-sidebar-iconview-bg;

    .logo {
      display: block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }

    .nav {
      background-color: transparent;
      margin: 15px auto;

      .nav-link {
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin: 10px auto;
        padding: 0px;
        border-radius: 4px;

        &.active {
          background-color: $twocolumn-iconview-icon-active-bg;
          box-shadow: var(--#{$prefix}box-shadow);
        }

        svg {
          color: $twocolumn-iconview-item-color;
          fill: $twocolumn-iconview-icon-active-bg;
          height: 22px;
          width: 22px;
        }

        i {
          color: $twocolumn-iconview-item-color;
          font-size: 22px;
        }
      }
    }
  }

  .sidebar-main-menu {
    display: block;
    position: fixed;
    width: $twocolumn-sidebar-width;
    background-color: $menu-bg;
    top: $topbar-height;
    bottom: 0;
    left: $twocolumn-sidebar-iconview-width;
    padding: 30px 5px;
    box-shadow: var(--#{$prefix}box-shadow);
    transition: all 0.1s ease-out;

    .sidebar-menu-body {
      padding: 20px;
    }

    .menu-title {
      color: $menu-item-color;
      margin: 0;
      padding: 10px 20px;
      letter-spacing: 0.05em;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    .nav {
      > .nav-item {
        > .nav-link {
          color: $menu-item-color;
          font-size: $menu-item-size;
          font-family: $font-family-secondary;
        }

        .menu-arrow {
          right: 10px;
        }
      }

      .nav-link {
        position: relative;
        color: $menu-item-color;
        padding: 6px 15px;
        border-radius: 3px;
        margin: 3px 5px;

        &:hover,
        &:focus,
        &.active {
          color: $menu-item-active;
        }
      }

      .menuitem-active {
        > a.nav-link {
          color: $menu-item-active;
          background-color: $menu-item-active-bg;
        }

        a.active {
          color: $menu-item-active;
        }
      }
    }

    #sidebar-menu {
      .menu-arrow {
        top: 7px;
      }
    }

    .nav-second-level {
      padding-left: 15px;
      list-style: none;
    }

    .logo-box {
      display: block;
      width: $twocolumn-sidebar-width !important;
      left: $twocolumn-sidebar-iconview-width;

      .logo-lg {
        display: block !important;
      }

      .logo-sm {
        display: none !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-custom {
      left: $twocolumn-sidebar-iconview-width !important;
      padding-left: 0px;

      .logo-box {
        width: $twocolumn-sidebar-width;
        display: block;
        position: relative;
        float: left;

        .logo-sm {
          display: none;
        }
      }
    }

    .sidebar-main-menu {
      .logo-box {
        display: none;
      }
    }

    &[data-sidebar-size="condensed"] {
      .logo-box {
        width: 0 !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .content-page {
      margin-left: calc(#{$twocolumn-sidebar-iconview-width} + #{$twocolumn-sidebar-width});
    }

    .footer {
      left: calc(#{$twocolumn-sidebar-iconview-width} + #{$twocolumn-sidebar-width});
    }

    &[data-sidebar-size="condensed"] {
      .sidebar-main-menu {
        display: none;
      }
    }
  }

  .twocolumn-menu-item {
    display: none;
  }

  &[data-layout-width="boxed"] {
    .navbar-custom {
      max-width: calc(#{$boxed-layout-width} - #{$twocolumn-sidebar-iconview-width});
    }

    .sidebar-main-menu {
      position: absolute;
    }
  }

  &[data-layout-width="boxed"]:not([data-sidebar-size="condensed"]) {
    .footer {
      max-width: calc(
        #{$boxed-layout-width} - #{calc(
            #{$twocolumn-sidebar-iconview-width} + #{$twocolumn-sidebar-width}
          )}
      );
    }
  }

  &[data-sidebar-color="gradient"] {
    .sidebar-main-menu {
      background-image: linear-gradient(270deg, rgba(64, 149, 216, 0.15), transparent);
    }
  }
}
