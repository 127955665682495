//
// profile.scss
//

.post-user-comment-box {
  background-color: var(--#{$prefix}gray-100);
  margin: 0 -0.75rem;
  padding: 1rem;
  margin-top: 20px;
}
